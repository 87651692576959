<script lang="ts">
	import StaticPicture from "./Picture/StaticPicture.svelte";
	import { nbspify } from "../../../core/utils/nbspify.js";

	export let title: string;
	export let image: string;
	export let imageMobile: string;
	export let index: number;
	export let urlSlug: string;
</script>

<a
	href="/{urlSlug}"
	class="group flex w-full max-w-[17.5rem] cursor-pointer flex-col sm:w-[20.125rem] lg:max-w-[14.5rem] xl:max-w-[20.125rem]"
>
	<span class="font-quicksand group-hover:text-tertiary text-gray text-end text-[4.375rem] lg:text-[10rem]">
		{index}
	</span>
	<div class="relative">
		<StaticPicture
			{image}
			width={322}
			height={402}
			alt=""
			class="w-full max-w-[17.5rem] sm:w-[20.125rem] lg:max-w-[14.5rem] xl:max-w-[20.125rem]"
			imgClass="h-[16.25rem] lg:h-[20rem] xl:h-[25rem] w-full object-cover"
			sourcesets={{
				"<lg": imageMobile,
				">lg": image,
			}}
		/>
		<div
			class="bg-secondary absolute inset-0 opacity-0 mix-blend-color transition-opacity duration-200 group-hover:opacity-100"
		></div>
		<div class="bg-secondary absolute inset-0 opacity-0 transition-opacity duration-200 group-hover:opacity-40"></div>
		<span
			class="font-quicksand absolute left-1/2 top-1/2 z-20 hidden -translate-x-1/2 -translate-y-1/2 text-[14rem] text-white group-hover:inline"
		>
			+
		</span>
	</div>
	<p class="group-hover:text-tertiary text-bito-black mt-4 text-base font-extrabold md:text-[1.5rem]">
		{nbspify(title)}
	</p>
</a>
