<script lang="ts">
	import service1 from "../assets/images/service1.png?webp&w=800&imagetools";
	import service2 from "../assets/images/service2.png?webp&w=800&imagetools";
	import service3 from "../assets/images/service3.png?webp&w=800&imagetools";
	import service4 from "../assets/images/service4.png?webp&w=800&imagetools";
	import service1Mobile from "../assets/images/service1.png?webp&h=260&imagetools";
	import service2Mobile from "../assets/images/service2.png?webp&h=260&imagetools";
	import service3Mobile from "../assets/images/service3.png?webp&h=260&imagetools";
	import service4Mobile from "../assets/images/service4.png?webp&h=260&imagetools";
	import { serviceEquipPathMap } from "../../../core/schema/paths/serviceEquipPathMap.js";
	import { getGlobalSettings } from "../getGlobalSettings.js";
	import ServiceCard from "./ServiceCard.svelte";
	import { serviceSparePartsPathMap } from "../../../core/schema/paths/serviceSparePartsPathMap.js";
	import { serviceInspectionPathMap } from "../../../core/schema/paths/serviceInspectionPathMap.js";
	import { serviceLogisticsPathMap } from "../../../core/schema/paths/serviceLogisticsPathMap.js";

	const { TENANT_LOCALE } = getGlobalSettings();

	const services = [
		{
			title: "Vybavíme váš sklad podle potřeby",
			urlSlug: serviceEquipPathMap[TENANT_LOCALE],
			image: service1,
			imageMobile: service1Mobile,
		},
		{
			title: "Navrhneme logistické řešení pro každý sklad",
			urlSlug: serviceLogisticsPathMap[TENANT_LOCALE],
			image: service2,
			imageMobile: service2Mobile,
		},
		{
			title: "Provedeme inspekci vašeho skladu",
			urlSlug: serviceInspectionPathMap[TENANT_LOCALE],
			image: service3,
			imageMobile: service3Mobile,
		},
		{
			title: "Náhradní díly nebo servis? Není problém",
			urlSlug: serviceSparePartsPathMap[TENANT_LOCALE],
			image: service4,
			imageMobile: service4Mobile,
		},
	];
</script>

{#each services as service, index}
	<ServiceCard
		urlSlug={service.urlSlug}
		index={index + 1}
		title={service.title}
		image={service.image}
		imageMobile={service.imageMobile}
	/>
{/each}
